import React, { useEffect, useState } from "react";
import "../styles/components/foundation_construct.css";
import { Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Box } from "@mui/material";
import rightarrow from "../assets/images/icons/white-right-arrow.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import first_top_recent_donors from "../assets/images/icons/donor.png";
import anonymous_donors from "../assets/images/icons/anonymous.png";
import { noauthinstance } from "../utils/api";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router-dom";
import { useAppContext } from "./Auth";

function Foundation_construct({
  donation,
  totalDonation,
  donationAmount,
  setDonationAmount,
  setIsDonationEdited,
  setCountersReset,
  minimumDonation,
  setMinimum,
  minimumAmount,
}) {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [defaultDonation, setDefaultDonation] = useState(null);
  const [showInputField, setShowInputField] = useState(false); // State to control input visibility
  const [error, setError] = useState(""); // State for error message
  const [Donations, setDonations] = useState([]);
  const [firstCName, setFirstCName] = useState();
  const [LastCName, setLastCName] = useState();
  const [HighCName, setHighCName] = useState();
  const [HighestCustomer, setHighestCustomer] = useState();
  const { slug } = useParams();
  const navigate = useNavigate();
  const { isLoggedIn, setIsLoggedIn, open, setOpen, phone, setPhone } = useAppContext();

  const handleAmountChange = (e) => {
    let value = e.target.value;
    setDonationAmount(value);
    setCountersReset((prev) => prev + 1);
    setIsDonationEdited(true);
    setError(""); // Clear error on change
  };

  const handleDonateClick = () => {
    // Validation when clicking "I Donate" button
    if (!donationAmount || parseInt(donationAmount, 10) < minimumAmount) {
      setError(`Please enter at least ₹${minimumAmount}`);
    } else {
      setError(""); // Clear error if validation passes
      // Navigate to checkout or perform donation logic here
      navigate(`/checkout/${donation.slug}/${donation.id}`);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);

    const foundDefaultDonation = donation.donation_amounts.find(
      (amount) => amount.is_default
    );
    if (foundDefaultDonation) {
      setDonationAmount(Math.round(foundDefaultDonation.price));
      setDefaultDonation(Math.round(foundDefaultDonation.price));
    }

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  async function getDonation() {
    try {
      const response = await noauthinstance.get(`donation_orders/${slug}`);
      const response2 = await noauthinstance.get(
        `donation_orders_recent/${slug}`
      );
      const currentDate = new Date();

      if (response2.status === 200) {
        const orderDateObj = new Date(
          response2.data.recent_orders[0].order_date
        );
        const differenceMs = currentDate - orderDateObj;

        const personalDetails = JSON.parse(
          response2.data.recent_orders[0].personal_details.replace(/'/g, '"')
        );
        setDonations(response2.data.recent_orders);

        const firstDonor =
          response2.data.recent_orders.length > 0
            ? JSON.parse(
                response2.data.recent_orders[0].personal_details.replace(
                  /'/g,
                  '"'
                )
              )
            : {};

        const lastDonor =
          response2.data.recent_orders.length > 0 &&
          response2.data.recent_orders[response2.data.recent_orders.length - 1]
            .is_anyomouns === false
            ? JSON.parse(
                response2.data.recent_orders[
                  response2.data.recent_orders.length - 1
                ].personal_details.replace(/'/g, '"')
              )
            : {};

        const firstAndLastName =
          firstDonor.firstName + " " + firstDonor.lastName;
        setFirstCName(firstAndLastName);
        const lastAndLastName = lastDonor.firstName + " " + lastDonor.lastName;
        setLastCName(lastAndLastName);

        let highestAmount = parseFloat(
          response.data.all_orders[0].original_amount
        );
        let highestPaidCustomer = response.data.all_orders[0];

        response.data.all_orders.forEach((order) => {
          const originalAmount = parseFloat(order.original_amount);
          if (originalAmount > highestAmount) {
            highestAmount = originalAmount;
            highestPaidCustomer = order;
          }
        });

        const highestDonor = JSON.parse(
          highestPaidCustomer.personal_details.replace(/'/g, '"')
        );
        const highestName =
          highestDonor.firstName + " " + highestDonor.lastName;
        setHighCName(highestName);
        setHighestCustomer(highestPaidCustomer);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getDonation();
  }, []);

  localStorage.setItem("donationAmount", donationAmount);
  const width =
    totalDonation > donation.target_amount
      ? 100
      : Math.round((100 * totalDonation) / donation.target_amount);

  const total_percentage =
    totalDonation > donation.target_amount
      ? ((totalDonation - donation.target_amount) / donation.target_amount) *
        100
      : (100 * totalDonation) / donation.target_amount;
  const rounded_percentage = Math.round(total_percentage);
  const extra_amount =
    totalDonation > donation.target_amount
      ? Math.round(totalDonation - donation.target_amount)
      : 0;
  const options = { maximumFractionDigits: 2 };

  return (
    <>
      <div className="new_foundation_construct">
        <div className="new_foundation_construct_bg">
          <h3>{donation.name}</h3>
          {isMobile && (
            <div className="donation_bg">
              <img
                src={`${donation.banner_image.base_url}${donation.banner_image.image_path}`}
                alt="Donation Banner"
              />
            </div>
          )}
          <ul className="date_event_location">
            <li className="first-child date_event_location_sm">
              <span>Date :</span>
              {new Date(donation.start_date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })}
            </li>
            <li className="second-child">
              <span>Location :</span>
              {donation.location}
            </li>
            <li className="third-child date_event_location_sm">
              <span>Event :</span>
              {donation.event}
            </li>
            {isMobile && (<li className="organiser_hand"><span>Org / Trust :</span>{donation.temple.name}</li> )}
          </ul>
          <div className="donate_bg">
            <div className="product_percentage">
              <h3>
                {totalDonation > donation.target_amount ? (
                  <>
                    Additional <span>{rounded_percentage}%</span> raised
                  </>
                ) : (
                  <>
                    <span>{rounded_percentage}%</span> raised
                  </>
                )}
              </h3>
              <Box className="product_percentage_count">
                <Box
                  sx={{
                    width: `${width}%`,
                  }}
                  className="product_percentage_count_child"
                ></Box>
                <div className="product_percentage_count_child_1"></div>
                <div className="product_percentage_count_child_2"></div>
              </Box>
              <h4>
                ₹{Math.round(totalDonation).toLocaleString("en-IN")} raised out
                of ₹
                {Math.round(donation?.target_amount).toLocaleString("en-IN")}
                {Math.round(totalDonation) > Math.round(donation?.target_amount)
                  ? `(Additional amount of ₹${extra_amount.toLocaleString(
                      "en-IN"
                    )} collected)`
                  : " "}
              </h4>
            </div>
            {donation.donation_expired === false && (
              <>
                <div className="donate_cost_buttons">
                  <ul>
                    {donation.donation_amounts.map((amount) =>
                      amount.is_active ? (
                        <li
                          key={amount.id}
                          className={
                            Math.round(amount.price) === defaultDonation ? "mostdonated" : ""
                          }
                        >
                          {Math.round(amount.price) === defaultDonation && (
                            <span className="default-donation">Most Donated</span>
                          )}
                          <Button
                            className={donationAmount === Math.round(amount.price) ? "active" : ""}
                            onClick={() => {
                              setMinimum(false);
                              setDonationAmount(Math.round(amount.price));
                              setIsDonationEdited(true);
                              setCountersReset((prev) => prev + 1);
                              setShowInputField(false);
                            }}
                          >
                            ₹{Math.round(amount.price).toLocaleString("en-IN")}
                          </Button>
                        </li>
                      ) : null
                    )}
                    <li>
                      <Button
                        onClick={() => {
                          setShowInputField(!showInputField);
                          setDonationAmount(""); // Clear the amount field for custom input
                        }}
                        className="enter-amount-btn"
                      >
                        Enter amount
                      </Button>
                    </li>
                  </ul>
                </div>
                {showInputField && (
                  <div className="enter_amount outlined_amount">
                    <FormControl fullWidth sx={{ m: 1 }}>
                      <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
                      <OutlinedInput
                        value={donationAmount}
                        onChange={handleAmountChange}
                        id="outlined-adornment-amount"
                        label="Amount"
                        type="number"
                        inputProps={{ min: "1" }}
                        startAdornment={<InputAdornment position="start">₹</InputAdornment>}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={() => setShowInputField(false)}>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    {error && <span className="error_message">{error}</span>}
                    {!error && minimumDonation && <span className="enter_more_price">Enter ₹{minimumAmount} or more</span>}
                  </div>
                )}
              </>
            )}
          </div>
          {donation.donation_expired === false && (
            <div className="donate_now donate_now_sm">
              {rounded_percentage >= 100 ? (
                <Button className="target_reached_btn">Target Reached</Button>
              ) : (
                <Button onClick={handleDonateClick}>
                  I Donate ₹ {Intl.NumberFormat("en-IN", options).format(donationAmount)}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Foundation_construct;
